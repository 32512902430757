
@import "../_variables.scss";
@import "../_hamburgers.scss";

.Nav {
  position: fixed;
  font-family: 'Fjalla One', sans-serif;
  font-size: 3vh;
  padding-left: 1%;
  padding-top: 10px;
  text-transform: uppercase;

  ul {
    display: inline-flex;
    flex-direction: row-reverse;
    list-style: none;
    margin: 0;
    padding: 0;
  }

  li {
    padding: 0 5px;

    &.active a {
      border-bottom: 4px solid $gold-dk;
    }
  }

  a {
    text-decoration: none;
    color: $font-color;
  }

  .hamburger {
    outline: none;
  }
}


.Nav-toggle {
  display: none;
}

// Mobile
@media (max-width: $small-cutoff) {
  .Nav {
    padding: 0;

    ul {
      bottom: $footer-height;
      display: block;
      left: 0;
      margin: 0;
      padding: 10px 0;
      position: fixed;
      text-align: left;
      width: 100%;
      opacity: 0;
      transition: all 0.5s 0.2s;
      visibility: hidden;

      &.active {
        @include overlay;

        opacity: 1;
        padding-top: 100vh;
        transition: all 0.5s;
        visibility: visible;
      }
    }

    li {
      padding: 5px 20px;
      font-size: 5vh;

      &.active::before {
        border-left: 4px inset $gold;
        content: '';
        left: -10px;
        margin-right: -4px;
        position: relative;
      }
      &.active a {
        border-bottom: none;
      }
    }
  }

  .Nav-toggle {
    display: block;
  }
}

