
@import "../../components/variables";

.Rsvp {
  margin: 0 auto;
  max-width: $medium-cutoff;

  section {
    margin: 1vh 0;
  }
}
