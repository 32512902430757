.Credits {
  section {
    margin-bottom: 50px;
    padding: 10px 20px;
    max-width: 900px;
    p {
      margin: 1em auto;
      max-width: 700px;
    }
  }
  h3 {
    margin: 0.8em 0 0.5em;
  }
  h4 {
    margin: 0.2em 0;
  }
  .contact {
    margin-top: 0;
  }

  .frame {
    max-height: 450px;

    &.happily {
      max-width: 450px;
      object-position: 0px 55%;
    }
    &.jessi {
      max-width: 450px;
      object-position: 0 85%;
    }
  }
}
