
@import "../../components/variables";

.Travel {
  margin: 0 auto;
  max-width: $medium-cutoff;

  section {
    margin: 3vh 0;
  }

  p {
    padding: 0 2vh;
    text-align: left;
  }

  .headline {
    text-align: center;
  }

  .Link {
    text-decoration: underline;
  }

  .location-card {
    margin: 2vh auto;

    h3,
    p {
      margin: 0 0 1vh 0;
      text-align: center;
    }
  }
}
