
@import "../../components/variables";

.Home {
  h1 {
    margin: 3vh;
    line-height: 1em;

    @media (max-width: $small-cutoff) {
      font-size: 7vh;
      margin-top: 1em;
    }
  }

  h2 {
    margin: 0.5em 0;
  }

  h3 {
    margin: 0.2em 0;
  }

  .Divider {
    margin: 5vh auto;
  }
}
