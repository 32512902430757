@charset "UTF-8";
* {
  box-sizing: border-box; }

body {
  margin: 0;
  padding: 0;
  font-family: 'Fjalla One', sans-serif;
  color: #272926; }

h1 {
  font-size: 10vh;
  margin-bottom: 0.5em;
  text-transform: uppercase; }

h2 {
  font-size: 5vh;
  margin-bottom: 0.5em;
  text-transform: uppercase; }

h3 {
  color: rgba(75, 63, 0, 0.65);
  margin-bottom: 0.5em;
  font-size: 3vh; }

h4 {
  font-size: 2vh; }

p {
  font-family: 'EB Garamond', serif; }

.headline {
  font-style: italic;
  text-align: center; }

a {
  color: #272926; }
  a:hover {
    text-decoration: underline; }

dl {
  font-family: 'EB Garamond', serif;
  text-align: left;
  margin: 2vh; }
  dl p {
    margin: 0 0 5px 0; }

dt {
  font-style: italic;
  font-weight: bold;
  margin-bottom: 2px; }

dd {
  margin: 0 0 20px 0; }

.a {
  font-family: 'EB Garamond', serif; }

.b {
  font-family: 'Comfortaa', sans-serif; }

.c {
  font-family: 'Source Sans Pro', sans-serif; }

.d {
  font-family: 'Ubuntu', sans-serif; }

.e {
  font-family: 'Fjalla One', sans-serif; }

img.frame {
  filter: drop-shadow(1px 5px 5px rgba(75, 63, 0, 0.65));
  max-height: 350px;
  max-width: 750px;
  object-fit: cover;
  object-position: 0 80%;
  padding: 15px;
  width: 90%; }
  img.frame.vertical {
    max-width: 500px;
    object-fit: cover;
    object-position: 0 35%; }
  @media (max-width: 600px) {
    img.frame {
      padding: 15px 0;
      width: 100%; } }

img.hero {
  max-height: 620px;
  max-width: 1700px;
  object-fit: cover;
  width: 100%; }

.image-credit {
  font-size: 12px;
  margin: 0 auto 10px;
  max-width: 1700px;
  padding: 0 10px;
  text-align: right; }

.page-container {
  padding-bottom: 9vh;
  margin: 0 auto; }
  .page-container section {
    margin: 0 auto;
    max-width: 700px; }

.location a {
  color: #272926;
  text-decoration: none; }
  .location a:hover {
    text-decoration: underline; }

.location::before {
  font-style: normal;
  content: "📍"; }

.tag {
  background: #6A7B56;
  border-radius: 5px;
  color: white;
  display: inline-block;
  font-size: 2vh;
  padding: 5px 12px;
  text-transform: uppercase; }

form {
  padding: 2vh; }
  form .radio-set {
    display: flex;
    margin: 0 auto;
    max-width: 30vh; }
  form .radio-set-option {
    flex-grow: 1; }
  form label,
  form input,
  form textarea {
    font-size: 16px; }
  form label {
    margin: 0 0 1vh 0;
    width: 100%;
    display: inline-block; }
  form input[type="radio"] {
    appearance: none;
    display: none; }
  form input[type="radio"] + label {
    border-radius: 30px;
    border: 1px solid rgba(75, 63, 0, 0.65);
    cursor: pointer;
    display: inline-block;
    line-height: 5vh;
    width: 10vh;
    transition: all 0.5s; }
  form input[type="radio"]:checked + label {
    background-color: #6A7B56;
    color: #FFF; }
  form input[type="text"],
  form input[type="email"],
  form textarea {
    -webkit-appearance: none;
    border-radius: 0;
    border: 1px solid rgba(75, 63, 0, 0.65);
    font-family: 'EB Garamond', serif;
    margin: 0 0 1vh 0;
    padding: 1vh;
    width: 100%; }
  form textarea {
    height: auto;
    resize: vertical; }
  form input[type="submit"] {
    -webkit-appearance: none;
    background: none;
    border-radius: 30px;
    border: 1px solid rgba(75, 63, 0, 0.65);
    cursor: pointer;
    display: inline-block;
    font-family: 'Fjalla One', sans-serif;
    line-height: 5vh;
    min-height: 40px;
    min-width: 120px;
    transition: all 0.5s;
    width: 15vh; }
    form input[type="submit"]:hover {
      background-color: #6A7B56;
      color: #FFF; }

.App {
  border-bottom: 9vh solid white;
  bottom: 0;
  height: 100vh;
  left: 0;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  width: 100%; }
  .App .Mountain {
    background: #06342E; }

.Wedding {
  background: url("../img/web_background_A.png");
  background-size: auto;
  background-position: top;
  background-repeat: no-repeat; }

.Mountain {
  background: url("../img/mountain.jpg");
  background-position: 40%;
  background-size: cover; }

.App-footer {
  background-color: rgba(255, 255, 255, 0.8);
  bottom: 0;
  color: #111;
  height: 9vh;
  left: 0;
  max-height: 9vh;
  position: fixed;
  right: 0;
  text-align: right; }
  @supports (-webkit-backdrop-filter: blur(10px)) or (backdrop-filter: blur(10px)) {
    .App-footer {
      -webkit-backdrop-filter: blur(10px);
      backdrop-filter: blur(10px);
      background-color: transparent; } }

.App-footer h2 {
  font-size: 3vh;
  margin: 0;
  padding-right: 2%;
  padding-top: 10px; }

/* Large Desktop */
@media (min-width: 1200px) {
  .Wedding {
    background-size: contain; }
  .App {
    border-bottom: 8vh solid white; }
  .App-footer {
    height: 8vh; } }

@media (max-width: 360px) {
  .App-footer {
    max-height: 10vh;
    height: 10vh; } }
