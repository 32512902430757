// Settings
// ==================================================
$hamburger-padding-x           : 15px !default;
$hamburger-padding-y           : 15px !default;
$hamburger-layer-width         : 40px !default;
$hamburger-layer-height        : 4px !default;
$hamburger-layer-spacing       : 6px !default;
$hamburger-layer-color         : #000 !default;
$hamburger-layer-border-radius : 4px !default;
$hamburger-hover-opacity       : 0.7 !default;
$hamburger-active-layer-color  : $hamburger-layer-color !default;
$hamburger-active-hover-opacity: $hamburger-hover-opacity !default;

// To use CSS filters as the hover effect instead of opacity,
// set $hamburger-hover-use-filter as true and
// change the value of $hamburger-hover-filter accordingly.
$hamburger-hover-use-filter   : false !default;
$hamburger-hover-filter       : opacity(50%) !default;
$hamburger-active-hover-filter: $hamburger-hover-filter !default;

$hamburger-types: (
  collapse
) !default;

// Base Hamburger (We need this)
// ==================================================
@import "../../node_modules/hamburgers/_sass/hamburgers/_base.scss";

// Hamburger types
// ==================================================
// @import "types/3dx";
// @import "types/3dx-r";
// @import "types/3dy";
// @import "types/3dy-r";
// @import "types/3dxy";
// @import "types/3dxy-r";
// @import "types/arrow";
// @import "types/arrow-r";
// @import "types/arrowalt";
// @import "types/arrowalt-r";
// @import "types/arrowturn";
// @import "types/arrowturn-r";
// @import "types/boring";
// @import "types/collapse";
@import "../../node_modules/hamburgers/_sass/hamburgers/types/collapse";
// @import "types/collapse-r";
// @import "types/elastic";
// @import "types/elastic-r";
// @import "types/emphatic";
// @import "types/emphatic-r";
// @import "types/minus";
// @import "types/slider";
// @import "types/slider-r";
// @import "types/spin";
// @import "types/spin-r";
// @import "types/spring";
// @import "../../node_modules/hamburgers/_sass/hamburgers/types/spring";
// @import "types/spring-r";
// @import "types/stand";
// @import "types/stand-r";
// @import "types/squeeze";
// @import "types/vortex";
// @import "../../node_modules/hamburgers/_sass/hamburgers/types/vortex";
// @import "types/vortex-r";
