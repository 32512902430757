@import './variables';

form {
  padding: 2vh;

  .radio-set {
    display: flex;
    margin: 0 auto;
    max-width: 30vh;
  }

  .radio-set-option {
    flex-grow: 1;
  }

  label,
  input,
  textarea {
    font-size: 16px;
  }

  label {
    margin: 0 0 1vh 0;
    width: 100%;
    display: inline-block;
  }


  input[type="radio"] {
    appearance: none;
    display: none;
  }

  input[type="radio"] + label {
    border-radius: 30px;
    border: 1px solid $gold-dk;
    cursor: pointer;
    display: inline-block;
    line-height: 5vh;
    width: 10vh;
    transition: all 0.5s;
  }

  input[type="radio"]:checked + label {
    background-color: $green;
    color: #FFF;
  }

  input[type="text"],
  input[type="email"],
  textarea {
    -webkit-appearance: none;
    border-radius: 0;
    border: 1px solid $gold-dk;
    font-family: 'EB Garamond', serif;
    margin: 0 0 1vh 0;
    padding: 1vh;
    width: 100%;
  }

  textarea {
    height: auto;
    resize: vertical;
  }

  input[type="submit"] {
    -webkit-appearance: none;
    background: none;
    border-radius: 30px;
    border: 1px solid $gold-dk;
    cursor: pointer;
    display: inline-block;
    font-family: 'Fjalla One', sans-serif;
    line-height: 5vh;
    min-height: 40px;
    min-width: 120px;
    transition: all 0.5s;
    width: 15vh;

    &:hover {
      background-color: $green;
      color: #FFF;
    }
  }
}
