
@import "../components/variables";

// Frame an image
img.frame {
  filter: drop-shadow(1px 5px 5px $gold-dk);
  max-height: 350px;
  max-width: 750px;
  object-fit: cover;
  object-position: 0 80%;
  padding: 15px;
  width: 90%;

  &.vertical {
    max-width: 500px;
    object-fit: cover;
    object-position: 0 35%;
  }

  @media (max-width: $small-cutoff) {
    padding: 15px 0;
    width: 100%;
  }
}

// Hero image
img.hero {
  max-height: 620px;
  max-width: $large-cutoff + 500;
  object-fit: cover;
  width: 100%;
}

// Credit shown below an image
.image-credit {
  font-size: 12px;
  margin: 0 auto 10px;
  max-width: $large-cutoff + 500;
  padding: 0 10px;
  text-align: right;
}

// Wrapper around each page
.page-container {
  padding-bottom: $footer-height;
  margin: 0 auto;

  section {
    margin: 0 auto;
    max-width: $medium-cutoff;
  }
}

.location {
  a {
    color: $font-color;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  &::before {
    font-style: normal;
    content: "📍";
  }
}

.tag {
  background: $green;
  border-radius: 5px;
  color: white;
  display: inline-block;
  font-size: 2vh;
  padding: 5px 12px;
  text-transform: uppercase;
}
