
$x-small-cutoff: 360px;
$small-cutoff: 600px;
$medium-cutoff: 700px;
$large-cutoff: 1200px;

$footer-height: 9vh;

$overlay: rgba(255, 255, 255, 0.8);

$light-green: #9EFB8F;
$dark-green: #06342E;
$light-blue: #38B1F8;
$dark-blue: #020D28;
$gray-dk: rgb(39, 41, 38);
$gold: rgba(202, 171, 0, 0.95);
$gold-dk: darken(rgba(202, 171, 0, 0.65), 25%);
$green: #6A7B56;

$font-color: $gray-dk;

@mixin overlay {
  background-color: $overlay;
  // opacity: 1;
  // transition: all 0.5s;

  @supports ((-webkit-backdrop-filter: blur(10px)) or (backdrop-filter: blur(10px))) {
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    background-color: transparent;
  }
}

@mixin shadow {
  box-shadow: 1px 1px 4px 0px rgba(75, 63, 0, 0.35);
}
