@import '../../components/variables';

.Registry {
  p {
    padding: 0 2vh;
  }

  section {
    margin: 5vh auto;
  }
}
