* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Fjalla One', sans-serif;
  color: $font-color;
}

h1 {
  font-size: 10vh;
  margin-bottom: 0.5em;
  text-transform: uppercase;
}

h2 {
  font-size: 5vh;
  margin-bottom: 0.5em;
  text-transform: uppercase;
}

h3 {
  color: $gold-dk;
  margin-bottom: 0.5em;
  font-size: 3vh;
}

h4 {
  font-size: 2vh;
}

p {
  font-family: 'EB Garamond', serif;
}

.headline {
  font-style: italic;
  text-align: center;
}

a {
  color: $font-color;

  &:hover {
    text-decoration: underline;
  }
}

dl {
  font-family: 'EB Garamond', serif;
  text-align: left;
  margin: 2vh;

  p {
    margin: 0 0 5px 0;
  }
}

dt {
  font-style: italic;
  font-weight: bold;
  margin-bottom: 2px;
}

dd {
  margin: 0 0 20px 0;
}

.a {
  font-family: 'EB Garamond', serif;
}

.b {
  font-family: 'Comfortaa', sans-serif;
}

.c {
  font-family: 'Source Sans Pro', sans-serif;
}

.d {
  font-family: 'Ubuntu', sans-serif;
}

.e {
  font-family: 'Fjalla One', sans-serif;
}
