@import "../../components/variables";

.Star {
  padding-bottom: $footer-height;

  img {
    max-width: 90px;

    @media (max-width: $small-cutoff) {
      max-width: 75px;
    }
  }
}
